/* Import CSS Plugins and main SCSS */
import 'animate.css/animate.min.css';
import 'aos/dist/aos.css';
import 'swiper/swiper-bundle.css';
import 'vanilla-cookieconsent/dist/cookieconsent.css'

/* Import Js Plugins (ES6 Module) and custom JS */
import AOS from 'aos';
import Rellax from 'rellax';
import Swiper from 'swiper/swiper-bundle';
import ActiveMenuLink from 'active-menu-link';
import GLightbox from 'glightbox';
import { Accordion, Modal } from 'flowbite';

import '../plugins/cookieconsent-init';

/* Global variables */
let lastScroll = 0;
let delta = 5;
let headerHeight = document.querySelector('header').offsetHeight;

const UTILS = {
    init() {
        AOS.init();
        new Rellax('.rellax');
        GLightbox({
            touchNavigation: true,
            autoplayVideos: true
        });
        new Swiper('.services-swiper', {
            //allowTouchMove: false,
            navigation: {
                prevEl: '.service-swiper-prev',
                nextEl: '.service-swiper-next'
            },
            pagination: {
                el: '.service-swiper-pagination',
                clickable: true,
            },
        });
        this.activeMenuLink();
        this.mobileNavigationMenu();
        this.copyPrintYear();
    },
    activeMenuLink() {
        let options = {
            //showHash: false,
            activeClass: 'is-active'
        };
        new ActiveMenuLink('.mb__primary-nav', options);
    },
    mobileNavigationMenu() {
        let menuLinks = document.querySelectorAll('.mb__mobile-primary-nav a');
        menuLinks.forEach((link) => {
           link.addEventListener('click', (e) => {
               document.querySelector('.drawer-menu-button').click();
           });
        });
    },
    headerAnimate() {
        let scrollTop = document.documentElement.scrollTop
        if (Math.abs(lastScroll - scrollTop) <= delta)
            return;
        if (scrollTop > lastScroll && scrollTop > headerHeight) {
            // scroll down
            document.querySelector('header').classList.remove('down');
            document.querySelector('header').classList.add('up');
        } else {
            // scroll up
            if (scrollTop + window.innerHeight < document.documentElement.scrollHeight) {
                document.querySelector('header').classList.remove('up');
                document.querySelector('header').classList.add('down');
            }
            if (scrollTop <= 50) {
                document.querySelector('header').classList.remove('down');
            }
        }
        lastScroll = scrollTop;
    },
    copyPrintYear() {
        const year = new Date().getFullYear();
        document.querySelector('footer .year').innerText = year;
    }
}

export { UTILS }