'use strict';

/* Import CSS Plugins and main SCSS */
import 'normalize.css/normalize.css'
import 'glightbox/src/postcss/glightbox.css'
import '../css/main.scss';

/* Import custom JS */
import { UTILS } from './utils/utils';

window.h5 = {
    init: () => {
        UTILS.init();
    }
};

window.onload = () => {
    setTimeout((e) => {
        document.querySelector('.mb__loading-screen').classList.add('animate__fadeOut');
        document.querySelector('header').classList.remove('hidden');
        document.querySelector('main').classList.remove('hidden');
        document.querySelector('.mb__loading-screen').classList.add('hidden');
        window.h5.init();
    }, 2000);
};

window.onscroll = () => {
    window.onload.effectScroll = true;
}

setInterval(() => {
    if (window.onload.effectScroll) {
        UTILS.headerAnimate();
        window.onload.effectScroll = false;
    }
}, 200);