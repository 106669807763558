import * as CookieConsent from 'vanilla-cookieconsent';

/**
 * All config. options available here:
 * https://cookieconsent.orestbida.com/reference/configuration-reference.html
 */
CookieConsent.run({
    cookie: {
        name: 'cc_mondonuovobaby',
    },
    guiOptions: {
        consentModal: {
            layout: 'box',
            position: 'bottom right',
            equalWeightButtons: true,
            flipButtons: false
        },
        preferencesModal: {
            layout: 'box',
            equalWeightButtons: true,
            flipButtons: false
        }
    },
    categories: {
        necessary: {
            enabled: true,  // this category is enabled by default
            readOnly: true  // this category cannot be disabled
        },
        analytics: {}
    },
    language: {
        default: 'it',
        translations: {
            it: {
                consentModal: {
                    title: 'Utilizziamo i cookie!',
                    description: 'Ciao, questo sito web utilizza cookie essenziali per garantire il suo corretto funzionamento e cookie di tracciamento per capire come interagisci con esso.',
                    acceptAllBtn: 'Accetta',
                    acceptNecessaryBtn: 'Rifiuta',
                    showPreferencesBtn: 'Gestisci preferenze individuali'
                },
                preferencesModal: {
                    title: 'Gestisci le preferenze sui cookie',
                    acceptAllBtn: 'Accetta tutti',
                    acceptNecessaryBtn: 'Rifiuta tutti',
                    savePreferencesBtn: 'Accetta la selezione corrente',
                    closeIconLabel: 'Chiudi modale',
                    sections: [
                        {
                            title: 'Qualcuno ha detto... cookies?',
                            description: 'Ne voglio uno!'
                        },
                        {
                            title: 'Cookie strettamente necessari',
                            description: 'Questi cookie sono essenziali per il corretto funzionamento del sito web e non possono essere disattivati.',
                            linkedCategory: 'necessary'
                        },
                        {
                            title: 'Performance and Analytics',
                            description: 'Questi cookie raccolgono informazioni su come utilizzi il nostro sito web. Tutti i dati sono resi anonimi e non possono essere utilizzati per identificarti.',
                            linkedCategory: 'analytics',
                            cookieTable: {
                                headers: {
                                    name: "Nome",
                                    domain: "Servizio",
                                    description: "Descrizione",
                                    expiration: "Scadenza"
                                },
                                body: [
                                    {
                                        name: "_ga",
                                        domain: "Google Analytics",
                                        description: "Cookie impostato da Google Analytics, utilizzato per distinguere gli utenti.",
                                        expiration: "2 anni"
                                    },
                                    {
                                        name: "_ga_id-contenitore",
                                        domain: "Google Analytics",
                                        description: "Cookie impostato da Google Analytics, utilizzato per mantenere lo stato della sessione.",
                                        expiration: "2 anni"
                                    }
                                ]
                            }
                        },
                        {
                            title: 'Più innformazioni',
                            description: 'Per qualsiasi domanda relativa alla mia politica sui cookie e alle tue scelte, ti preghiamo di scrivere a <a href="mailto:asilo@mondonuovoonlus.org">asilo@mondonuovoonlus.org</a>'
                        }
                    ]
                }
            }
        }
    }
});